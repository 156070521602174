import { EpUpload } from '@/utils';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

export default function RichText({ value, onChange }: { value?: string; onChange?: (content: string) => void }) {
  const editorRef = useRef(null);
  console.log('tinymceScriptSrc', `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`);
  console.log('tinymceScriptSrc', '/tinymce/tinymce.min.js');
  console.log('tinymceScriptSrc', process.env.PUBLIC_URL);
  const path = `${process.env.PUBLIC_URL}tinymce/tinymce.min.js`;
  return (
    <Editor
      tinymceScriptSrc={path}
      licenseKey="your-license-key"
      onInit={(_evt, editor) => {
        editorRef.current = editor;
        editor.on('Change', () => {
          if (onChange) {
            onChange(editor.getContent());
          }
        });
      }}
      initialValue={value}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'preview',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        images_upload_handler: (blobInfo) =>
          new Promise((resolve) => {
            if (blobInfo.filename().includes('http')) {
              resolve(blobInfo.filename());
              return;
            }
            const blob = blobInfo.blob();
            const file = new File([blob], blobInfo.filename(), { type: blob.type });
            EpUpload.singleUpload({
              attachmentType: 1,
              module: 'opus',
              file: file,
            }).then((url) => {
              resolve(url);
            });
          }),
      }}
    />
  );
}
