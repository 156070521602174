import RichText from '@/compnents/richText';
import { Button, Divider, Form, Input, InputNumber, Modal, Radio, Select } from 'antd';
import type { FormInstance } from 'antd/lib';
import JoditEditor from 'jodit-react';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import type EditProtocolModel from './editComboModel';

const EditProtocol = ({ store }: { store: EditProtocolModel }) => {
  return (
    <Modal
      title={`${store.type}套餐`}
      open={store.visible}
      onCancel={store.handleClose}
      maskClosable={false}
      width={1000}
      okButtonProps={{ className: 'mr-16' }}
      onOk={store.confirm}
    >
      <Form
        ref={store.setForm}
        labelCol={{ span: 4 }}
        layout="horizontal"
        wrapperCol={{ span: 18 }}
        autoComplete="off"
      >
        <Form.Item
          label="套餐名称"
          name="name"
          rules={[{ required: true, max: 20 }]}
        >
          <Input
            className="w-50"
            placeholder="请输入"
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          label="终端"
          name="source"
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: 200 }}
            labelInValue={true}
            options={[
              {
                label: '主播端',
                value: 1,
              },
              {
                label: '雇主端',
                value: 2,
              },
            ]}
            placeholder="请选择"
            onChange={(e) => {
              store.getServiceList(e.value);
            }}
          />
        </Form.Item>
        <p className="text-14 ml-5">服务内容</p>
        <ContentForm store={store} />
        <Form.Item
          label="套餐售价"
          name="salePrice"
          rules={[{ required: true }]}
        >
          <InputNumber
            readOnly={true}
            className="border-none outline-none"
          />
        </Form.Item>
        <Form.Item
          label="套餐划线价"
          name="originalPrice"
          rules={[{ required: true }]}
        >
          <Input
            className="w-50"
            placeholder="请输入"
          />
        </Form.Item>
        <Form.Item
          label="套餐介绍"
          name="intro"
          rules={[{ required: true }]}
        >
          <RichText />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ContentForm = observer(({ store }: { store: EditProtocolModel }) => {
  return (
    <>
      {store.formItems.map((item) => {
        return (
          <div
            key={item.id}
            className="border-b mt-5 border-[#f0f0f0]"
          >
            <div>
              <Form.Item
                label="选择服务"
                name={`functionType_${item.id}`}
                rules={[{ required: true }]}
              >
                <Select
                  labelInValue={true}
                  style={{ width: 200 }}
                  options={store.serviceList}
                  onChange={(e) => {
                    store.onChangeValidTime(item.id, e.value);
                  }}
                  placeholder="请选择"
                />
              </Form.Item>
              <Form.Item
                label="数量"
                name={`validNum_${item.id}`}
                rules={[{ required: true }]}
              >
                <InputNumber
                  step={1}
                  precision={0}
                  min={0}
                  className="w-50"
                  placeholder="请输入"
                  onChange={(value) => {
                    store.updateUnitPrice(item.id, 'validNum', value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="售价"
                name={`salePrice_${item.id}`}
                rules={[{ required: true }]}
              >
                <InputNumber
                  step={0.01}
                  precision={2}
                  min={0}
                  className="w-50"
                  placeholder="请输入"
                  onChange={(value) => {
                    store.updateUnitPrice(item.id, 'price', value);
                    store.updateSellingPrice();
                  }}
                />
              </Form.Item>
              <Form.Item
                label="单价"
                name={`unitPrice_${item.id}`}
              >
                <InputNumber
                  readOnly={true}
                  className="border-none "
                />
              </Form.Item>
              <Form.Item
                label="生效时间"
                name={`validTimeType_${item.id}`}
                rules={[{ required: true }]}
              >
                <Radio.Group disabled={true}>
                  <Radio value="1">不限</Radio>
                  <Radio value="2">有效期</Radio>
                </Radio.Group>
              </Form.Item>
              {item.validTimeType == '2' ? (
                <div className="flex">
                  <Form.Item
                    label="购买日起"
                    name={`validDay_${item.id}`}
                    rules={[{ required: true }]}
                    labelCol={{ span: 14 }}
                  >
                    <InputNumber
                      className="w-50"
                      placeholder="请输入"
                      step={1}
                      precision={0}
                      min={0}
                    />
                  </Form.Item>
                  <div className="ml-25 mt-2">天内生效</div>
                </div>
              ) : null}
              {store.formItems.length > 1 && (
                <div className="flex justify-end">
                  <Button
                    type="link"
                    onClick={() => {
                      store.removeFormItem(item.id);
                    }}
                  >
                    删除
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <Button
        type="primary"
        className="mt-2"
        onClick={store.addFormItem}
      >
        添加服务
      </Button>
    </>
  );
});
export default observer(EditProtocol);
