import { type BaseData, request, transformKeys } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from '../api';
import type ParentModel from '../model';
import type { ProtocolVO } from '../type';

export default class EditProtocolModel {
  constructor(parentModel: ParentModel) {
    this.parentModel = parentModel;
  }
  @observable public serviceList: any = [];
  @observable visible = false;
  @observable parentModel: ParentModel = null;
  @observable form: FormInstance = null;
  @observable type: '新增' | '编辑' = '新增';
  @observable row: ProtocolVO = null;
  @observable public id: number;
  @observable public formItems: any[] = [
    {
      id: '1',
      functionType: '',
      salePrice: '',
      validNum: '',
      unitPrice: '',
      validTimeType: '',
      validDay: '',
    },
  ];
  public terminalDict = [
    { label: '主播端', value: '1' },
    { label: '雇主端', value: '2' },
  ];
  // 获取服务列表
  @action public getServiceList = (source: number) => {
    request<BaseData<any>>({
      url: '/pbb/platform/sys/service/project/list',
      method: 'POST',
      data: {
        pageNum: 1,
        pageSize: 100,
        source,
      },
    }).then((res) => {
      this.serviceList = transformKeys(res.data.list, {
        name: 'label',
        id: 'value',
      });
    });
  };

  @action public addFormItem = () => {
    const newItem = {
      id: Date.now().toString(), // 使用时间戳作为唯一标识
      functionType: '',
      salePrice: '',
      validNum: '',
      unitPrice: '',
      validTimeType: '',
      validDay: '',
    };
    this.formItems = [...this.formItems, newItem];
  };

  // 删除表单项
  @action public removeFormItem = (id) => {
    this.formItems = this.formItems.filter((item) => item.id !== id);
    this.updateSellingPrice(); // 重新计算总价
  };

  // 计算单价
  @action public updateUnitPrice = (id: any, field: string, value: number) => {
    this.form.setFieldsValue({
      [`${field}_${id}`]: value,
    });

    const num = this.form.getFieldValue(`validNum_${id}`);
    const price = this.form.getFieldValue(`salePrice_${id}`);

    // 计算单价
    const unitPrice = price / num;
    if (!Number.isNaN(unitPrice)) {
      this.form.setFieldsValue({
        [`unitPrice_${id}`]: unitPrice.toFixed(2),
      });
    } else {
      this.form.setFieldsValue({
        [`unitPrice_${id}`]: '',
      });
    }
  };

  // 计算总价
  @action public updateSellingPrice = () => {
    const prices = this.formItems.map((item) => {
      return this.form.getFieldValue(`salePrice_${item.id}`) || 0;
    });

    // 计算总价
    const totalPrice = prices.reduce((sum, price) => sum + price, 0);
    this.form.setFieldsValue({
      salePrice: totalPrice.toFixed(2),
    });
  };

  // 是否生效时间
  @action public onChangeValidTime = (id, type) => {
    let value = '';
    if (['7', '9', '14'].includes(type)) {
      value = '1';
    } else if (['11', '12', '13'].includes(type)) {
      value = '2';
    }
    this.form.setFieldsValue({
      [`validTimeType_${id}`]: value,
    });
    this.formItems = this.formItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          validTimeType: value,
        };
      }
      return item;
    });
  };

  @action handleOpen = async (row?: ProtocolVO) => {
    if (row) {
      this.type = '编辑';
      this.row = row;
      this.id = row.id;
      if (this.row.source) {
        this.getServiceList(this.row.source);
        const terminalValue = this.terminalDict.find((item) => item.value == this.row.source);
        this.row.source = terminalValue || { label: '', value: '' };
      }
      if (this.row.projectList.length) {
        this.formItems = this.row.projectList.map((item) => {
          let value = '';
          if ([7, 9, 14].includes(item.functionType)) {
            value = '1';
          } else if ([11, 12, 13].includes(item.functionType)) {
            value = '2';
          }
          return {
            id: item.id,
            functionType: item.functionType,
            name: item.name,
            salePrice: item.salePrice,
            validNum: item.validNum,
            unitPrice: item.unitPrice,
            validTimeType: value,
            validDay: item.validDay,
          };
        });
      }

      const formValues = {
        name: this.row.name,
        source: this.row.source,
        originalPrice: this.row.originalPrice,
        intro: this.row.intro,
        salePrice: this.row.salePrice,
        type: this.row.type,
        ...this.formItems.reduce((acc, item, index) => {
          const functionTypeValue = {
            label: item.name,
            value: item.functionType,
          };

          acc[`functionType_${item.id}`] = functionTypeValue;
          acc[`validNum_${item.id}`] = item.validNum;
          acc[`salePrice_${item.id}`] = item.salePrice;
          acc[`unitPrice_${item.id}`] = item.unitPrice;
          acc[`validTimeType_${item.id}`] = item.validTimeType;
          acc[`validDay_${item.id}`] = item.validDay;
          return acc;
        }, {}),
      };
      // 设置1ms  保证editor渲染
      setTimeout(() => this.form.setFieldsValue(formValues), 1);
    }

    if (!row) {
      this.type = '新增';

      this.formItems = [
        {
          id: '1',
          functionType: '',
          salePrice: '',
          validNum: '',
          unitPrice: '',
          validTimeType: '',
          validDay: '',
        },
      ];

      this.serviceList = [];
    }

    this.visible = true;
  };
  @action handleClose = () => {
    this.visible = false;
    this.form.resetFields();
  };
  @action setForm = (ref: FormInstance) => {
    this.form = ref;
  };
  public confirm = async () => {
    await this.form.validateFields();
    const url = this.type === '编辑' ? API.comboUpdate : API.comboAdd;
    const postParams = { ...this.form.getFieldsValue(true) };

    if (postParams.service) {
      postParams.projectList = [];
    }

    if (postParams.source) {
      postParams.source = postParams.source.value;
    }

    if (this.type === '编辑') {
      postParams.id = this.id;
    }

    const functionTypeCounts = {};
    const hasDuplicate = Object.keys(postParams)
      .filter((key) => key.startsWith('validNum_'))
      .some((key) => {
        const id = key.replace('validNum_', '');
        const functionType = postParams[`functionType_${id}`].value;

        if (functionTypeCounts[functionType]) {
          message.error('不能选择相同的服务');
          return true;
        } else {
          functionTypeCounts[functionType] = true;
          return false;
        }
      });

    if (hasDuplicate) {
      return;
    }

    const projectList = Object.keys(postParams)
      .filter((key) => key.startsWith('validNum_'))
      .map((key) => {
        const id = key.replace('validNum_', '');
        const project = {
          functionType: postParams[`functionType_${id}`].value,
          id: postParams[`functionType_${id}`].value,
          salePrice: postParams[`salePrice_${id}`],
          unitPrice: postParams[`unitPrice_${id}`],
          validDay: postParams[`validDay_${id}`],
          validNum: postParams[key],
          validTimeType: postParams[`validTimeType_${id}`],
        };
        postParams[key] = undefined;
        postParams[`unitPrice_${id}`] = undefined;
        postParams[`salePrice_${id}`] = undefined;
        postParams[`validTimeType_${id}`] = undefined;
        postParams[`validDay_${id}`] = undefined;
        postParams[`functionType_${id}`] = undefined;

        return project;
      });

    postParams.projectList = projectList;

    request<BaseData<boolean>>({
      url,
      method: 'POST',
      data: {
        ...postParams,
      },
    }).then(() => {
      message.success(`${this.type}成功`);
      this.parentModel.comboStore.onQuery();
      this.handleClose();
    });
  };
}
