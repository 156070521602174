import { type BaseData, MainSubStructureModel, RenderByPermission, copyToClipboard, getWxUrl, request } from '@/utils';
import { Button, Modal, message } from 'antd';
import dayjs from 'dayjs';
import { API } from './api';
import EditProtocolModel from './modal/editComboModel';

export default class WhiteListModel {
  constructor() {
    this.refresh();
  }
  public editComboStore = new EditProtocolModel(this);
  public comboStore: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增套餐',
        permissionId: '2',
        handleClick: () => {
          this.editComboStore.handleOpen();
        },
      },
    ],

    grid: {
      columns: [
        {
          name: '操作',
          key: 'operations',
          width: 230,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                {row.canUpdate ? (
                  <RenderByPermission permissionId={`${pageId}_3`}>
                    <Button
                      type="link"
                      onClick={() => {
                        this.editComboStore.handleOpen(row);
                      }}
                    >
                      编辑
                    </Button>
                  </RenderByPermission>
                ) : (
                  ''
                )}

                <RenderByPermission permissionId={`${pageId}_148`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.copyComboLink(row.id, row.source);
                    }}
                  >
                    复制套餐链接
                  </Button>
                </RenderByPermission>
                {row.canDelete === 1 ? (
                  <RenderByPermission permissionId={`${pageId}_4`}>
                    <Button
                      type="link"
                      onClick={() => {
                        this.handleDelete(row.id);
                      }}
                    >
                      删除
                    </Button>
                  </RenderByPermission>
                ) : (
                  ''
                )}
              </div>
            );
          },
        },
        {
          name: '套餐名称',
          key: 'name',
        },
        {
          name: '终端',
          key: 'source',
          formatter: ({ row }) => {
            return row.source ? (row.source === 1 ? '主播端' : '雇主端') : '';
          },
        },
        {
          name: '服务内容',
          key: 'projectList',
          width: 250,
          formatter: ({ row }) => {
            const { projectList } = row;
            const project = projectList.map((item) => ({ ...item }));
            return project?.map((item) => {
              if (item.functionType) {
                if ([7, 9, 14].includes(item.functionType)) {
                  item.validDay = '不限';
                }
                if ([11, 12, 13].includes(item.functionType)) {
                  item.validDay = `${item.validDay}天`;
                }
                if (item.salePrice) {
                  item.salePrice = Number.parseFloat(item.salePrice).toFixed(2);
                }
                if (item.unitPrice) {
                  item.unitPrice = Number.parseFloat(item.unitPrice).toFixed(2);
                }
              }

              return (
                <div
                  key={item.id}
                  className="mt-3 leading-[20px] flex flex-col justify-center"
                >
                  <p>
                    服务名称：<span>{item.name}</span>
                  </p>
                  <p>
                    数量：<span>{item.validNum}</span>
                  </p>
                  <p>
                    售价：<span>{item.salePrice}</span>
                  </p>
                  <p>
                    单价：<span>{item.unitPrice}</span>
                  </p>
                  <p>
                    生效时间：<span>{item.validDay}</span>
                  </p>
                </div>
              );
            });
          },
        },
        {
          name: '套餐零售价',
          key: 'salePrice',
          formatter: ({ row }) => {
            if (!row.salePrice) {
              return '';
            }
            return Number.parseFloat(row.salePrice).toFixed(2);
          },
        },
        {
          name: '套餐划线价',
          key: 'originalPrice',
          formatter: ({ row }) => {
            if (!row.originalPrice) {
              return '';
            }
            return Number.parseFloat(row.originalPrice).toFixed(2);
          },
        },
        {
          name: '更新人',
          key: 'updatePerson',
          width: 200,
          formatter: ({ row }) => {
            if (!row.updateBy) {
              return '';
            }
            return (
              <div className="h-[100%] leading-[20px] flex flex-col justify-center">
                <p>
                  用户昵称：<span>{row.updateByName}</span>
                </p>
                <p>
                  ID：<span>{row.updateBy}</span>
                </p>
                <p>
                  手机号：<span>{row.updateByMobile}</span>
                </p>
              </div>
            );
          },
        },
        {
          name: '更新时间',
          key: 'updateTime',
          formatter: ({ row }) => {
            if (row.updateTime) {
              return dayjs(row.updateTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return null;
          },
        },
        {
          name: '创建时间',
          key: 'createTime',
          formatter: ({ row }) => {
            if (row.createTime) {
              return dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return null;
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rowHeight: ({ row }) => {
        const serviceList = row.projectList;
        if (serviceList) {
          // 高度调整
          return (serviceList.length - 1) * 120 + 120;
        }
        return 120;
      },
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { ...rest } = params;
        return request<BaseData<any>>({
          url: API.comboList,
          method: 'post',
          data: { ...rest },
        });
      },
    },
  });

  public copyComboLink = (id: number, source: number) => {
    getWxUrl({
      channel: 12,
      source: source,
      path: source === 1 ? 'detailsPackage/pages/ad-page/meal/index' : 'landingPackage/ad-page/meal/index',
      servicePackageId: id,
    }).then((url) => {
      if (!url) {
        message.error('复制失败');
        return;
      }
      copyToClipboard(url);
      message.success('复制套餐链接成功');
    });
  };
  public handleDelete = (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除该套餐吗？',
      onOk: async () => {
        await request({
          url: API.comboDelete,
          method: 'post',
          data: { id },
        });
        this.comboStore.onQuery();
      },
    });
  };

  public refresh = () => {
    this.comboStore.onQuery();
  };
}
