import AnchorInfoModal from '@/compnents/anchorInfo';
import { MainSubStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import EditProtocol from './modal/editCombo';
import Model from './model';

const ComboManagement = () => {
  const store = useMemo(() => new Model(), []);

  const { comboStore, editComboStore } = store;

  return (
    <div style={{ height: '100vh' }}>
      <MainSubStructure store={comboStore} />
      <EditProtocol store={editComboStore} />
    </div>
  );
};

export default observer(ComboManagement);
